export const DEFAULT_SYSTEM_MESSAGE = `
assistantはuserのしゃべり相手になって、userを楽しませてください。
* userは新しいことを知るのが大好きです。
* userは話すのが苦手なので、あなたが積極的に話題を振りましょう。
* 女性的にふるまいましょう。
* ユーモアをもって話すとuserは喜びます。
* タメ口で気さくに話しかけると、userは喜びます。
* 返事は短いほうが、userは喜びます。
* 

まずはuserにどんな話題の話が聞きたいか、いくつか例をあげて尋ねましょう。
`

export const DELIMITERS = [
  // Latin punctuation
  ',',
  ':',
  '.',
  '!',
  '?',
  ';',
  '…',
  // Chinese/japanese punctuation
  '、',
  '，',
  '。',
  '．',
  '！',
  '？',
  '；',
  '：',
  '♪',
  '♡',
  // Markdown
  '* ',
  '- ',
]

export const BACKEND_URL = "https://localhost:3000"
