export const makeSentence = () => {
  let sentence = "";

  // sentenceにstrを追加して返す
  const add = (str) => {
    sentence = sentence + str;
    return sentence;
  }

  // sentenceにstrを設定して返す
  const set = (str) => {
    sentence = str;
    return sentence;
  }

  // sentenceを返す
  const get = () => {
    return sentence;
  }

  return {
    add,
    set,
    get
  }
}
