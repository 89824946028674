import { useState, useEffect } from 'react';

import { DELIMITERS } from 'constants/constants';
import { fetchAudioArrayBuffer } from 'utils/audioHelpers';
import { makeAudioController } from 'utils/makeAudioController';
import { makeSentence } from 'utils/makeSentence';

export const useAudios = () => {
  const [sentence] = useState(() => makeSentence());
  const [audioController] = useState(() => makeAudioController());

  useEffect(() => {
    // マウント時にループを開始する
    audioController.playLoop();

    // アンマウント時にループを停止する
    return () => {
      audioController.stopLoop();
    };
  }, [audioController]);

  const processMessageStream = async (additionalContent) => {
    // ガード節
    if (!additionalContent) {
      return
    }

    const delimiterPosition = findFirstDelimiter(additionalContent);
    // 区切り文字が含まれていない場合、sentenceにadditionalContentをそのまま追加
    if (delimiterPosition < 0) {
      sentence.add(additionalContent);
      return;
    }

    // 区切り文字が含まれている場合、_sentenceに区切り文字までの文節を取得する
    const _sentence = sentence.get() + additionalContent.slice(0, delimiterPosition + 1);

    // 文節のオーディオバッファを取得するPromiseをキューに追加
    try {
      const audioArrayBufferPromise = fetchAudioArrayBuffer(_sentence);
      audioController.addAudioQueue(audioArrayBufferPromise);
    } catch (error) {
      console.error(error);
    }

    // 区切り文字以降の部分をsentenceに設定する
    sentence.set(additionalContent.slice(delimiterPosition + 1));

  };

  return { processMessageStream };;
}

// strの中で、delimitersのいずれかの文字が最初に出現する位置を返す
// いずれの文字も出現しない場合は-1を返す
const findFirstDelimiter = (str, delimiters = DELIMITERS) => {
  return str.split('').findIndex(char => delimiters.includes(char));
}
