import React, { useContext } from 'react';
import { AppContext } from 'components/App';

const SettingsPage = () => {
  const { systemContent, setSystemContent } = useContext(AppContext);

  return (
    <div className={styles.wrap}>
      <div className={styles.spacer}>
        <textarea
          value={systemContent}
          onChange={(event) => setSystemContent(event.target.value)}
          className={styles.textArea}
        />
      </div>
    </div>
  );

}

export default SettingsPage;

const styles = {
  wrap: "bg-slate-700 h-screen flex justify-center items-stretch px-8 ",
  spacer: "flex flex-col items-stretch w-full h-full",
  textArea: "flex-1 resize-none p-2",
}