import React, { createContext, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { IoSettings, IoChevronBackOutline } from "react-icons/io5";
import 'components/reset.css';

import { DEFAULT_SYSTEM_MESSAGE } from "constants/constants";

export const AppContext = createContext();

const Root = () => {
  const [systemContent, setSystemContent] = useState(DEFAULT_SYSTEM_MESSAGE);
  const location = useLocation();

  return (
    <AppContext.Provider value={{ systemContent, setSystemContent }}>
      <div className={styles.menu}>
        {location.pathname === "/"
          ?
          <Link to={`settings`}>
            <IoSettings />
          </Link>
          :
          <Link to={`/`}>
            <IoChevronBackOutline />
          </Link>
        }
      </div>
      <div id="main">
        <Outlet />
      </div>
    </AppContext.Provider>
  );
}

export default Root;

const styles = {
  menu: "fixed top-3 left-3 text-lg text-white",
}
