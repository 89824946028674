import React, { useRef, useCallback, useContext } from 'react';
import { useMessages } from 'hooks/useMessages';
import { AppContext } from 'components/App';
import Messages from 'components/Messages';
import MicInput from 'components/MicInput';
import SendButton from './SendButton';

const Chat = () => {
  const textRef = useRef(null)
  const { messages, fetchMessageResponse } = useMessages();
  const { systemContent } = useContext(AppContext);

  const sendText = useCallback(async () => {
    if (textRef.current.value.trim() !== '') {
      const text = textRef.current.value;
      textRef.current.value = '';

      await fetchMessageResponse(text, systemContent);
    }
  }, [fetchMessageResponse, systemContent])

  const handleOnKeyDown = useCallback((e) => {
    if (e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      sendText();
    }
  }, [sendText]);


  return (
    <div className={styles.app}>
      <div className={styles.messageArea}>
        <Messages messages={messages} />
      </div>
      <div className={styles.inputArea}>
        <textarea
          placeholder='Enter your message'
          ref={textRef}
          onKeyDown={handleOnKeyDown}
          className={styles.textArea}
        />
        <div className={styles.buttonArea}>
          <MicInput fetchMessageResponse={fetchMessageResponse} textRef={textRef} />
          <SendButton handleOnClick={sendText}></SendButton>
        </div>
      </div>
    </div>
  );

}

export default Chat;

const styles = {
  app: "bg-slate-700 h-screen flex flex-col justify-between",
  messageArea: "grow overflow-y-auto p-2",
  inputArea: "flex flex-row p-2 bg-slate-800",
  textArea: "flex-grow",
  buttonArea: "flex flex-col text",
}