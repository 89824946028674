export const makeAudioController = () => {
  let audioQueue = [];
  let audioPlaying = false;
  let loopTimeoutId = null;
  const audioContext = new AudioContext();


  // 音声バッファ（ArrayBuffer）を取得するPromiseをaudioQueueに追加する関数
  const addAudioQueue = (audioArrayBufferPromise) => {
    audioQueue.push(audioArrayBufferPromise);
  }

  // 再生をループさせる関数
  const playLoop = async () => {
    // キューが空でなく、再生中でない場合にplayNextAudio()を呼び出す
    if (!audioPlaying && audioQueue.length > 0) {
      await playNextAudio();
    }

    // setTimeoutのIDを変数に保持して、次のループを実行する
    loopTimeoutId = setTimeout(playLoop, 100);
  };

  // ループを停止する関数
  const stopLoop = () => {
    if (loopTimeoutId !== null) {
      clearTimeout(loopTimeoutId);
      loopTimeoutId = null;
    }
  };

  // audioQueueが空になるまで、順番に再生
  const playNextAudio = async () => {

    audioPlaying = true;

    // キューが空になるまで、順番に再生
    while (audioQueue.length > 0) {
      // キューの先頭の音声バッファを取得して、キューから削除
      const nextBufferPromise = audioQueue.shift();
      // Promiseが解決されるまで待機
      const arrayBuffer = await nextBufferPromise;
      // 音声バッファを再生
      await playAudioBuffer(arrayBuffer);
    }

    audioPlaying = false;
  };

  // 音声バッファを再生する関数
  const playAudioBuffer = async (arrayBuffer) => {
    return new Promise(async (resolve) => {
      // ArrayBuffer を AudioBuffer に変換
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      source.start(0, 0, audioBuffer.duration - 0.7);

      source.onended = () => {
        source.disconnect();
        resolve();
      };
    });
  }

  return {
    playLoop,
    stopLoop,
    addAudioQueue
  }
}
