import { createBrowserRouter } from "react-router-dom";

import App from 'components/App';
import Chat from 'components/Chat';
import SettingsPage from 'components/SettingsPage';
import ErrorPage from 'components/ErrorPage';

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Chat />,
      },
      {
        path: "settings",
        element: <SettingsPage />,
      }
    ]
  }
]);