// メッセージを追加する関数
// newMessage: 追加するメッセージオブジェクト
export const addMessage = async (setMessages, newMessage) => {
  return new Promise(resolve => {
    setMessages(prevMessages => {
      const newMessages = [...prevMessages, newMessage];
      resolve(newMessages);
      return newMessages;
    });
  });
};

// 最後のメッセージに内容を追加する関数
// additionalContent: 追加するメッセージの内容
export const addMessageContent = async (setMessages, additionalContent) => {
  return new Promise(resolve => {
    setMessages(prevMessages => {
      // 既存のメッセージ配列の、最後のメッセージに内容を追加
      const newMessages = prevMessages.map((message, index) => {
        if (index === prevMessages.length - 1) {
          return { ...message, content: message.content + additionalContent };
        }
        return message;
      })
      resolve(newMessages);
      return newMessages;
    });
  });
};