import React, { memo } from 'react';
import Message from 'components/Message';

const Messages = memo(({ messages }) => (
  <div className={styles.wrapper}>
    <div className={styles.contents}>
      {messages.map((message, index) => (
        <Message key={index} role={message.role} content={message.content} />
      ))}
    </div>
  </div>
));

export default Messages;

const styles = {
  wrapper: "flex justify-center items-center",
  contents: "flex flex-col w-5/6",
}