import React, { memo } from 'react';

const Message = memo(({ role, content }) => (
  <div className={`${styles[role]} ${styles.message}`}>
    <span className={styles.text}>{content}</span>
  </div>
));

export default Message;

const styles = {
  message: 'text-left p-2 mb-4 rounded-lg max-w-4/5',
  user: 'self-end bg-green-300 text-black',
  assistant: 'self-start bg-green-100 text-black',
  text: 'whitespace-break-spaces leading-5'
}