// 与えられた文字列に基づいてAPIからオーディオバッファを取得する関数
export const fetchAudioArrayBuffer = (text) => {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ text: text }),
    };

    try {
      // const response = await fetch(`${BACKEND_URL}/api/hello/text2Speech`, requestOptions);
      // const response = await fetch(`${BACKEND_URL}/api/hello/text2SpeechWeb`, requestOptions);
      const response = await fetch(`https://c6ixviwyx6mrnb6h7oefyl6hvm0kqqps.lambda-url.ap-northeast-1.on.aws/`, requestOptions);

      if (!response.ok) {
        new Error(`API request failed with status ${response.status}`);
      }

      const blob = await response.blob();
      const arrayBuffer = await blob.arrayBuffer();

      resolve(arrayBuffer);
    } catch (error) {
      reject(error);
    }
  });
};
