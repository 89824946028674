import { useState, useEffect, useCallback } from 'react';
import { addMessage, addMessageContent } from 'utils/messageHelpers';
import { useAudios } from 'hooks/useAudios';

import { BACKEND_URL } from 'constants/constants';

export const useMessages = () => {
  const [messages, setMessages] = useState([]);
  const { processMessageStream } = useAudios();

  const fetchMessageResponse = useCallback(async (inputText, systemContent) => {

    // ユーザーが入力したテキストをメッセージとして追加する
    const updatedMessages = await addMessage(setMessages, { role: "user", content: inputText });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ systemContent: systemContent, messages: updatedMessages }),
    };

    try {
      const response = await fetch(`https://d67k4q6jgu2kar7ykgiylo3pzi0vocat.lambda-url.ap-northeast-1.on.aws/`, requestOptions);

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const textDecoder = new TextDecoder("utf-8");
      const reader = response.body.getReader();

      // アシスタントのメッセージを追加（空のコンテンツで初期化）
      addMessage(setMessages, { role: "assistant", content: "" });

      const readNextChunk = () => {
        requestAnimationFrame(async () => {
          const { done, value } = await reader.read();

          if (done) {
            return;
          }

          // チャンクをデコードし、行ごとに分割
          const chunk = textDecoder.decode(value, { stream: true });
          const lines = chunk.split('\n').filter(line => line.trim() !== '');

          for (const line of lines) {
            const data = line.replace(/^data: /, '');

            if (data === '[DONE]') {
              return;
            }

            try {
              // JSONとしてデータをパース
              const parsed = JSON.parse(data);
              const delta = parsed.choices[0].delta;

              // メッセージの内容を更新
              if (delta.content) {
                addMessageContent(setMessages, delta.content);
                processMessageStream(delta.content);
              }
            } catch (error) {
              console.error('Could not JSON parse stream data', data, error);
            }
          }
          // 次のチャンクを読み込むするために再帰的に呼び出し
          readNextChunk();
        });
      }

      // 最初のチャンクを読み込み
      await readNextChunk();

    } catch (error) {
      console.log(error);
    }
  }, [setMessages, processMessageStream]);

  useEffect(() => {
    // getMessage 関数の初期化やクリーンアップなどが必要であれば、ここで行う
    // 現状は不要なため"[]"を指定している
  }, []);

  return { messages, setMessages, fetchMessageResponse };;
};

