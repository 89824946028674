import React from "react";
import { IoSend } from "react-icons/io5";
import { IconContext } from "react-icons";

const SendButton = ({ handleOnClick }) => {
  return (
    <IconContext.Provider value={{ className: styles.button }}>
      <IoSend onClick={handleOnClick} />
    </IconContext.Provider>
  );
};
export default SendButton;

const styles = {
  button: "h-10 w-10 bg-green-400 hover:bg-green-300 text-white rounded-full px-2 py-2 m-1",
}