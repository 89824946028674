import React, { useState, useEffect, useRef, useContext } from 'react';
import { AppContext } from 'components/App';
import { IoMdMic, IoMdMicOff } from "react-icons/io";
import { IconContext } from "react-icons";
import { BACKEND_URL } from 'constants/constants';

const MicInput = ({ fetchMessageResponse, textRef }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const recordedChunksRef = useRef(recordedChunks);
  const { systemContent } = useContext(AppContext);

  useEffect(() => {
    const initAudioInput = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new MediaRecorder(stream);
        recorder.ondataavailable = (e) => {
          recordedChunksRef.current.push(e.data);
        };
        recorder.onstop = async () => {
          const text = await uploadAudio();
          if (text && text.trim() !== '') {
            fetchMessageResponse(text, systemContent);
          }
          recordedChunksRef.current = [];
        };
        setMediaRecorder(recorder);
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
    };

    initAudioInput();

  }, []);

  const startRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.start();
      setIsRecording(true);
    }
  };

  const stopRecording = async () => {
    if (mediaRecorder && isRecording) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  const uploadAudio = async () => {
    const recordedBlob = new Blob(recordedChunksRef.current, { type: 'audio/webm' });
    const formData = new FormData();
    formData.append('audio', recordedBlob, 'audio.webm');

    try {
      console.log('Uploading audio...')
      const response = await fetch(`https://ywjmlg47uo3hexn7bshw3ibd240dxfcj.lambda-url.ap-northeast-1.on.aws/`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Audio uploaded successfully');
        if (data.text) {
          return data.text;
          // textRef.current.value = data.text;
        }
      } else {
        console.error('Error uploading audio:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading audio:', error);
    }
  };


  return (
    <div className='mic-button'>
      {!isRecording ? (
        <IconContext.Provider value={{ className: styles.micButtonOn }}>
          <IoMdMic onClick={startRecording} />
        </IconContext.Provider>
      ) : (
        <IconContext.Provider value={{ className: styles.micButtonOff }}>
          <IoMdMicOff onClick={stopRecording} />
        </IconContext.Provider>
      )}
    </div>
  );
};

export default MicInput;

const styles = {
  micButtonOn: "h-10 w-10 bg-red-400 hover:bg-red-300 text-white rounded-full px-2 py-2 m-1",
  micButtonOff: "h-10 w-10 bg-red-300 hover:bg-red-400 text-white rounded-full px-2 py-2 m-1",
}